import { useCookie } from 'react-use';
import useSWR, { SWRConfiguration } from 'swr';

import { getMyAvatar } from 'actions/profileActions';

import { PROFILE_ME_URL } from 'tools/urls';
import { getSettingsUrl } from 'tools/urls/attendance/course';
import { getCourseUrl } from 'tools/urls/course';
import { getFormMetadataURL, getFormURL } from 'tools/urls/newForms';
import { NOTIFICATION_UNREAD_URL } from 'tools/urls/notifications';
import { RETENTION_SCHOOLS_URL } from 'tools/urls/retention';
import { USER_GROUPS_BY_STAFF_URL } from 'tools/urls/userGroups';
import { getUserUrl } from 'tools/urls/users';
import {
  getAutomationsMetadataUrl,
  getAutomationsSettingsUrl,
  getAutomationUrl,
} from 'tools/urls/workspaces/modules/automations';

import { useUserGroupsAvailableSchools } from 'components/users/userGroups';

import type { Settings } from 'tools/types/attendance';
import type { ContactLocation } from 'tools/types/contacts';
import type { Course } from 'tools/types/courses';
import type { Form, SendFormMetadata } from 'tools/types/newForms';
import type { NotificationsCount } from 'tools/types/notifications';
import type { Profile } from 'tools/types/profile';
import type { ProgramInfo } from 'tools/types/programs/admin/info';
import type { RetentionInfo } from 'tools/types/retention';
import type { SchoolAccount } from 'tools/types/school';
import type { GetUserGroupsAdmin, UserGroupBase } from 'tools/types/userGroups';
import type { UserInfo } from 'tools/types/users';
import type { AutomationMetadata } from 'tools/types/workspaces/automations';
import type { AutomationSettings } from 'tools/types/workspaces/automations/automation';
import type { AutomationDetails } from 'tools/types/workspaces/automations/details';

export const useAccounts = (): Array<SchoolAccount> => {
  return useSWR(`/api/v2/profile/account`, { revalidateOnMount: false }).data;
};

export const useAttendanceSettings = (
  courseID: number | string,
  ckEventID?: number | null,
): Settings => {
  return useSWR(getSettingsUrl({ ckEventID, courseID }), {
    revalidateOnMount: false,
  }).data;
};

export const useAutomationInfo = (
  automationID: string | number,
  workspaceID: string | number,
): AutomationDetails => {
  return useSWR(getAutomationUrl({ automationID, workspaceID }), {
    revalidateOnMount: false,
  }).data;
};

export const useAutomationsMetadata = (
  workspaceID: number | string,
): AutomationMetadata => {
  return useSWR(getAutomationsMetadataUrl({ workspaceID }), {
    revalidateOnMount: false,
  }).data;
};

export const useAutomationsSettings = (
  workspaceID: number | string,
): AutomationSettings => {
  return useSWR(getAutomationsSettingsUrl({ workspaceID }), {
    revalidateOnMount: false,
  }).data;
};

export const useCourse = (courseID: number | string): Course => {
  return useSWR(getCourseUrl({ courseID }), { revalidateOnMount: false }).data;
};

export const useIsAuth = () => {
  const [cookie] = useCookie('ki');
  return !!cookie;
};

export const useIsProfileFetched = (SWROptions: SWRConfiguration = {}) => {
  const profile = useProfile(SWROptions);
  return Boolean(profile.userID);
};

export const useNotificationsCount = (SWROptions: SWRConfiguration = {}) => {
  return useSWR<NotificationsCount>(NOTIFICATION_UNREAD_URL, {
    revalidateOnMount: false,
    ...SWROptions,
  });
};

/**
 * @todo remove default value to defaultToSchoolTimezone and userTimezone 1 sprint after release
 */
export const useProfile = (SWROptions: SWRConfiguration = {}): Profile => {
  const data = useSWR(PROFILE_ME_URL, {
    revalidateOnMount: false,
    ...SWROptions,
  }).data;

  if (data) {
    if (data.defaultToSchoolTimezone === undefined) {
      data.defaultToSchoolTimezone = true;
    }
    if (data.userTimezone === undefined) {
      data.userTimezone = null;
    }
    return data;
  } else {
    return {
      schoolPermissions: [],
    } as any;
  }
};

export const useProfileAvatar = (SWROptions: SWRConfiguration = {}): string => {
  return useSWR('/api/v2/profile/avatar', {
    fetcher: () => getMyAvatar(),
    revalidateOnMount: false,
    ...SWROptions,
  }).data!;
};

export const useProgramInfo = (programID?: number | string): ProgramInfo => {
  return useSWR(
    programID ? `/api/v3/requirementsTracker/program/${programID}` : null,
    {
      revalidateOnMount: false,
    },
  ).data;
};

export const useRetentionInfo = (): RetentionInfo => {
  return useSWR(RETENTION_SCHOOLS_URL, {
    revalidateOnMount: false,
  }).data;
};

export const useUserInfo = (userID: number): UserInfo => {
  return useSWR(getUserUrl({ userID }), {
    revalidateOnMount: false,
  }).data;
};

export const useForm = (formID: string): Form => {
  return useSWR(getFormURL({ formID }), {}).data;
};

export const useFormMetadata = (formID: string): SendFormMetadata => {
  return useSWR(getFormMetadataURL({ formID }), {}).data;
};

/** Use this hook to get the admin available user groups by schoolIDs. Available for either admins or instructors */
export const useStaffUserGroups = ({
  adminPermission,
  isContact = false,
  schoolIDs,
}: {
  /** Use this property to filter if the user needs admin view/edit permission on that group  */
  adminPermission?: 'view' | 'edit';
  isContact?: boolean;
  schoolIDs?: string[];
} = {}): UserGroupBase[] => {
  const { data } = useSWR<GetUserGroupsAdmin>(USER_GROUPS_BY_STAFF_URL, {});
  const schoolIDsWithManagementPermission = useUserGroupsAvailableSchools({
    editOnly: adminPermission === 'edit',
    isContact,
  }).map(({ schoolID }) => schoolID);

  let groups = data?.userGroups ?? [];
  if (schoolIDs) {
    groups = groups.filter(({ schoolID }) => schoolIDs.includes(schoolID));
  }

  if (adminPermission) {
    groups = groups.filter(({ schoolID }) =>
      schoolIDsWithManagementPermission.includes(schoolID),
    );
  }

  return groups;
};

export const useLocation = (): ContactLocation[] | undefined => {
  const response = useSWR<{ locations: ContactLocation[] }>(
    '/api/v3/contacts/contact/locations/all',
  ).data;
  return response?.locations;
};
